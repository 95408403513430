import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { useStaticQuery, graphql } from "gatsby"

export const TeamSection = () => {
  const data = useStaticQuery(graphql`
    {
      mdx(slug: { eq: "team/" }) {
        frontmatter {
          people {
            bio
            name
            role
            position
            image {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, width: 800)
              }
            }
          }
        }
      }
    }
  `)

  const people = data.mdx.frontmatter.people
  return (
    <div className="bg-white">
      <div className="max-w-7xl mx-auto py-12 px-4 text-center sm:px-6 lg:px-8 lg:py-24">
        <div className="space-y-12">
          <div className="space-y-5 sm:mx-auto sm:max-w-xl sm:space-y-4 lg:max-w-5xl">
            <h2 className="text-3xl font-extrabold tracking-tight sm:text-4xl">
              团队成员
            </h2>
          </div>
          <ul className="mx-auto space-y-16 sm:grid sm:grid-cols-2 sm:gap-16 sm:space-y-0 lg:grid-cols-3 lg:max-w-5xl">
            {people.map(person => (
              <li key={person.name}>
                <div className="space-y-6">
                  <GatsbyImage
                    className="mx-auto h-40 w-40 rounded-full xl:w-56 xl:h-56"
                    image={getImage(person.image)}
                    alt=""
                  />
                  <div className="space-y-2">
                    <div className="text-lg leading-6 font-medium space-y-1">
                      <h3>{person.name}</h3>
                      <p className="text-indigo-600">{person.role}</p>
                      <p className="text-gray-500">
                        <b>{person.position}</b>&nbsp;&nbsp;{person.bio}
                      </p>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  )
}

export default TeamSection
