import React from "react"
import Layout from "../components/layout"
import Profile from "../sections/profile"
import Team from "../sections/team"
import Contact from "../sections/contact"
import Seo from "../components/seo"

const AboutPage = () => {
  return (
    <Layout>
      <Seo title="关于我们" />
      <Profile />
      <Team />
      <Contact />
    </Layout>
  )
}

export default AboutPage
