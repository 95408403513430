import React from "react"
import { StaticImage } from "gatsby-plugin-image"

export const ContactSection = () => {
  return (
    <div className="bg-white">
      <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-24 lg:px-8">
        <div className="divide-y-2 divide-gray-200">
          <div className="lg:grid lg:grid-cols-3 lg:gap-8">
            <h2 className="text-2xl font-extrabold text-gray-900 sm:text-3xl">
              联系方式
            </h2>
            <div className="mt-8 grid grid-cols-1 gap-12 sm:grid-cols-2 sm:gap-x-8 sm:gap-y-12 lg:mt-0 lg:col-span-2">
              <div>
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  电话邮箱
                </h3>
                <dl className="mt-2 text-base text-gray-500">
                  <div className="mt-1">
                    <dt className="sr-only">Phone number</dt>
                    <dd>固定电话: 020-83841151</dd>
                    <dd>客服电话: 19128718631</dd>
                    <dd>电子邮箱: shengshixiong@ibrpa.com</dd>
                  </div>
                </dl>
              </div>
              <div>
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  办公地址
                </h3>
                <dl className="mt-2 text-base text-gray-500">
                  <div>
                    <dt className="sr-only">Address</dt>
                    <dd>广州市黄埔区光谱中路11号2栋3单元904房</dd>
                  </div>
                </dl>
              </div>
              <div>
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  微信公众号
                </h3>
                <dl className="mt-2 text-base text-gray-500">
                  <div className="mt-1">
                    <dd>省事熊IBRPA</dd>
                  </div>
                </dl>
                <div className="w-48 lg:w-56">
                  <StaticImage src="../images/微信公众.jpg" alt="省事熊IBRPA" />
                </div>
              </div>
              <div>
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  QQ交流群
                </h3>
                <dl className="mt-2 text-base text-gray-500">
                  <div className="mt-1">
                    <dd>769305956</dd>
                  </div>
                </dl>
                <div className="w-48 lg:w-56">
                  <StaticImage
                    src="../images/QQ交流群.png"
                    alt="QQ交流群769305956"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContactSection
