import React from "react"
import { StaticImage } from "gatsby-plugin-image"

export const ProfileSection = () => {
  return (
    <>
      <div className="relative bg-white">
        <div className="lg:absolute lg:inset-0">
          <div className="lg:absolute lg:inset-y-0 lg:left-0 lg:w-1/2">
            <StaticImage
              className="h-56 w-full object-cover lg:absolute lg:h-full"
              src="../images/云升科学园.jpg"
              alt=""
            />
          </div>
        </div>
        <div className="relative pt-12 pb-16 px-4 sm:pt-16 sm:px-6 lg:px-8 lg:max-w-7xl lg:mx-auto lg:grid lg:grid-cols-2">
          <div className="lg:col-start-2 lg:pl-8">
            <div className="text-base max-w-prose mx-auto lg:max-w-lg lg:ml-auto lg:mr-0">
              <h3
                id="profile"
                className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl"
              >
                公司简介
              </h3>
              <p className="mt-8 text-lg text-gray-500">
                广州省事熊智能科技有限公司是一家专注于IBRPA技术研发（AI人工智能、BPM业务流程管理、RPA机器人流程自动化）的信息化服务、高科技创新公司。
              </p>
              <div className="mt-5 text-lg prose prose-indigo text-gray-500">
                <p>
                  公司主要从事智能流程自动化产品的研发及服务工作，为能源、医疗、政务、教育、公检法、金融、保险等行业提供整套智能业务流程自动化解决方案。IBRPA云平台也为客户提供针对各类重复性和规则性工作的“虚拟数字员工”智能工具，帮助客户提升工作效率，节省人力成本。
                </p>

                <p>
                  本项目团队依托Petri网理论基础，实现了独特的流程挖掘和流程优化技术。与早期的RPA不同，采用了BPM技术中极具价值的部分，重点在流程图生成，业务重组难等技术上进行突破，形成显著优势的独特功能。结合各种AI技术，能够适应不断变化的用户需求，减少人工干预，节省大量的人力、物力、财力，最终实现极简操作，基于Petri网的RPA引擎为能源、政务、医疗、教育等行业提供了先进的业务流程解决方案，目前具有极大领先优势。
                </p>
                <p>
                  公司专注于IBRPA引擎研究，面向广大合作伙伴，形成IBRPA生态联盟，为企业客户、个人客户提供更先进的技术体验及服务！
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="petri"
        className="max-w-7xl mx-auto py-8 px-4 sm:px-6 lg:py-12 lg:px-8"
      >
        <h2 className="text-center text-3xl font-extrabold tracking-tight sm:text-4xl">
          理论创新成果
        </h2>

        <div className="mt-8 space-y-4 sm:grid sm:grid-cols-3 sm:gap-6 sm:space-y-0 lg:gap-8">
          <div className="w-200 h-300">
            <StaticImage
              object-fit="contain"
              className="shadow-lg rounded-lg"
              src="../images/certificate.png"
              alt=""
            />
          </div>
          <div className="sm:col-span-2">
            <div className="space-y-4">
              <div className="text-lg">
                <p className="text-gray-500">
                  创始人余阳教授是中国BPM大会（CBPM）指导委员会7个成员之一，与国际工作流理论创始团队Wil
                  M.P. van der Aalst教授、Arthur H.M. ter
                  Hofstede教授在过程挖掘、云工作流等领域有深入的科研合作。
                  <br></br> Wil M.P. van der
                  Aalst是德国亚琛大学的洪堡教授，欧洲科学院院士，他是IFIP
                  Fellow、IEEE Fellow、ACM
                  Fellow，世界上被引用次数最多的计算机科学家之一，也被称为“过程挖掘教父”。发表了
                  250 多篇期刊论文、22 本书（作为作者或编辑）、550
                  篇会议/研讨会出版物和 80 本书章节。 <br></br>Arthur H.M. ter
                  Hofstede是澳大利亚布里斯班昆士兰科技大学科学与工程学院信息系统学院的教授，并且是业务流程管理学科的负责人，荷兰埃因霍温埃因霍温理工大学工业工程学院信息系统组教授。研究兴趣涵盖业务流程自动化和流程挖掘领域。
                </p>
              </div>
            </div>
          </div>
        </div>

        <p className="text-indigo-600 text-center">合作项目/论文</p>

        <ul className="divide-y pt-4 divide-gray-200">
          <li className="py-4 flex bg-gray-50">
            <div className="ml-3">
              <p className="text-sm font-medium text-gray-900">
                无状态云工作流调度及过程协同关键技术研究，广东省科技计划国际科技合作项目（2020A0505100030），2020.9-2022.12
              </p>
            </div>
          </li>
          <li className="py-4 flex">
            <div className="ml-3">
              <p className="text-sm font-medium text-gray-900">
                云工作流关键技术研究及应用，广州市产学研协同创新重大专项（2016201604030001），2016.3-2019.1
              </p>
            </div>
          </li>
          <li className="py-4 flex bg-gray-50">
            <div className="ml-3">
              <p className="text-sm font-medium text-gray-900">
                云工作流平台的开发及应用，广东省科技发展专项国际合作项目（2016B050502006），2016.1-2017.12
              </p>
            </div>
          </li>
          <li className="py-4 flex">
            <div className="ml-3">
              <p className="text-sm font-medium text-gray-900">
                Michael Adams, Chun Ouyang, Arthur H.M. ter Hofstede, and Yang
                Yu. Design and Performance Analysis of Load Balancing Strategies
                for Cloud-Based Business Process Management Systems. Proceedings
                of On the Move to Meaningful Internet Systems OTM 2018
                Conferences — CoopIS2018. October 22-26, 2018, Valletta, Malta.
                pp 390-406. 【CORE-A类会议】
              </p>
            </div>
          </li>
          <li className="py-4 flex bg-gray-50">
            <div className="ml-3">
              <p className="text-sm font-medium text-gray-900">
                Chun Ouyang, Michael Adams, Arthur H. M. ter Hofstede, Yang
                Yu(通讯作者). Towards the Design of a Scalable Business Process
                Management System Architecture in the Cloud. Proceedings of the
                37th International Conference on Conceptual Modeling (ER 2018).
                October 22-25,2018,Xi’an, China. pp 334-348. 【CORE-A类会议】
              </p>
            </div>
          </li>
          <li className="py-4 flex">
            <div className="ml-3">
              <p className="text-sm font-medium text-gray-900">
                Jing Yang, Chun Ouyang, Maolin Pan, Yang Yu(通讯作者) and Arthur
                Ter Hofstede. Finding the Liberos: Discover Organizational
                Models with Overlaps. Proceedings of the 16th International
                Conference on Business Process Management(BPM 2018).September
                9-14,2018,Sydney, Australia. pp 339–355.
                【CORE-A类会议】工作流/BPM领域国际顶级会议
              </p>
            </div>
          </li>
          <li className="py-4 flex bg-gray-50">
            <div className="ml-3">
              <p className="text-sm font-medium text-gray-900">
                Jing Yang,Chun Ouyang,Wil MPvan der Aalstba,Arthur H.M.ter
                Hofstedea, Yang Yuc. A Framework for Discovering,Evaluating,and
                AnalyzingOrganizational Models using Event Logs
              </p>
            </div>
          </li>
        </ul>
      </div>
    </>
  )
}

export default ProfileSection
